<template>
  <div>
    <el-main>
      <search-device-location @submit="submitSearch" />

      <div class="table-btn-list">
        <el-button
            class="table-btn"
            type="primary"
            @click="showNewModal"
        >
          新增
        </el-button>
      </div>
      <el-table v-loading="loading" :data="deviceLocationList">
        <el-table-column width="100">
          <template #default="scope">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-image
                style="width: 55px; height: 55px"
                :src="backImgUrl"
                fit="contain"
              />
              <el-image
                style="width: 30px; height: 30px; position: absolute"
                :src="imgUrl"
                fit="contain"
              />
            </div>
            <div>
              <dict-tag-dark
                  :options="dict.type.device_location_status"
                  :value="scope.row.status"
              />
            </div>
            <div>
              <dict-tag-dark
                  v-if="
                scope.row.onlineStatus !==
                  selectDictValue(
                    dict.type.device_location_online_status,
                    'None'
                  ) && scope.row.onlineStatus !== ''
              "
                  :options="dict.type.device_location_online_status"
                  :value="scope.row.onlineStatus"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-row :span="24">
              <el-col :span="12">
                <span class="title">{{ scope.row.deviceNo? `${scope.row.deviceName}  ` + ` (${scope.row.deviceNo})`:scope.row.deviceName }}</span>
              </el-col>
              <el-col :span="12" style="text-align:right">
                <el-tooltip
                    v-if="scope.row.onlineStatus ===selectDictValue(dict.type.device_location_online_status,'NotOnline') &&
                    scope.row.status ===selectDictValue( dict.type.device_location_status,'Installed')"
                    content="上线设备"
                >
                  <el-icon
                      :size="20"
                      color="#1396db"
                      style="margin-right: 10px"
                      @click="onlineDevice(scope.row.id, scope.row)"
                  >
                    <Upload />
                  </el-icon>
                </el-tooltip>
                <el-tooltip content="操作历史">
                  <el-icon
                      :size="20"
                      color="#1396db"
                      style="margin-right: 10px"
                      @click="showOperationHistory(scope.row.id)"
                  >
                    <Clock />
                  </el-icon>
                </el-tooltip>

                <el-tooltip
                    content="编辑"
                    v-if="
                    scope.row.status ===
                    selectDictValue(dict.type.device_location_status, 'Draft')
                  "
                >
                  <el-icon
                      :size="20"
                      color="#1396db"
                      style="margin-right: 10px"
                      @click="showEditModal(scope.row)"
                  >
                    <Edit />
                  </el-icon>
                </el-tooltip>

                <el-tooltip
                    content="提交"
                    v-if="
                    scope.row.status ===
                    selectDictValue(dict.type.device_location_status, 'Draft')
                  "
                >
                  <el-icon
                      :size="20"
                      color="#1396db"
                      style="margin-right: 10px"
                      @click="submitDeviceLocation(scope.row.id)"
                  >
                    <CircleCheck />
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="删除">
                  <el-icon
                      :size="20"
                      color="#ff0000"
                      @click="handleDelete(scope.row)"
                  >
                    <Delete />
                  </el-icon>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="6">
                <el-descriptions :column="1" :border="true" >
                  <el-descriptions-item label="设备类型：">
                    {{ selectDictLabel(dict.type.device_type,scope.row.deviceType) }}
                  </el-descriptions-item>
                  <el-descriptions-item label="项目名称：">{{scope.row.project?.name }}</el-descriptions-item>
                  <el-descriptions-item label="项目类型：">{{scope.row.project?.projectType?.name }}</el-descriptions-item>
                  <el-descriptions-item label="所属区域：">{{scope.row.areaName }}</el-descriptions-item>
                  <el-descriptions-item label="详细地址:">{{scope.row.address }}</el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="6">
                <el-descriptions :column="1" :border="true" >
                  <el-descriptions-item label="安装位置：">{{selectDictLabel(dict.type.device_location_installation_location, scope.row.installationLocation) }}</el-descriptions-item>
                  <el-descriptions-item label="安装方式：">{{selectDictLabel(dict.type.device_location_installation_method, scope.row.installationMethod) }}</el-descriptions-item>
                  <el-descriptions-item
                      label="插座数量："
                      v-if="scope.row.installationMethod === selectDictValue(dict.type.device_location_installation_method,'WallMount')"
                  >
                    {{ scope.row.socketCount }}
                  </el-descriptions-item>
                  <el-descriptions-item
                      label="小桩数量："
                      v-if="scope.row.installationMethod === selectDictValue(dict.type.device_location_installation_method,'ColumnInstallation')"
                  >
                    {{ scope.row.pileCount }}
                  </el-descriptions-item>
                  <el-descriptions-item label="整体安装位置">
                    <template #default>
                      <el-image
                          v-for="url in scope.row.installationPositionFile"
                          style="width: 50px; height: 30px;margin: 0 10px"
                          :preview-src-list="[url]"
                          :src="url"
                          fit="contain"
                          preview-teleported="true"
                      />
                    </template>
                  </el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="6">
                <el-descriptions :column="1" :border="true" >
                  <el-descriptions-item label="点位图">
                    <template #default>
                      <el-image
                          v-for="url in scope.row.bitmapFile"
                          style="width: 50px; height: 30px;margin: 0 10px"
                          :src="url"
                          :preview-src-list="[url]"
                          fit="contain"
                          preview-teleported="true"
                      />
                    </template>
                  </el-descriptions-item>
                  <el-descriptions-item label="取电位置：">
                    <template #default>
                      <div
                          style="width: 100%"
                      >
                        <span> {{ scope.row.powerLocation }}</span>
                      </div>
                      <el-image
                          v-for="url in scope.row.powerLocationFile"
                          style="width: 50px; height: 30px;margin: 0 10px"
                          :src="url"
                          :preview-src-list="[url]"
                          fit="contain"
                          preview-teleported="true"
                      ></el-image>
                    </template>
                  </el-descriptions-item>
                  <el-descriptions-item label="线材预算">4mm²线-{{ scope.row.wireBudget4 }}米；<br/>1.5mm²线-{{scope.row.wireBudget15}}米</el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="6">
                <el-descriptions :column="1" :border="true" >
                  <el-descriptions-item label="安装费用：">{{scope.row.fee }}元</el-descriptions-item>
                  <el-descriptions-item label="安装说明：">{{scope.row.description }}</el-descriptions-item>
                </el-descriptions>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        v-model:page="query.pageNum"
        v-model:limit="query.pageSize"
        @pagination="fetchData"
      />

      <!-- 新增 模态框 -->
      <new-device-location
        v-if="isShowNewModal"
        v-model:visible="isShowNewModal"
        @completed="fetchData"
      />

      <!-- 编辑 模态框 -->
      <edit-device-location
        v-if="isShowEditModal"
        :id="editingId"
        v-model:visible="isShowEditModal"
        @completed="fetchData"
      />

      <operation-history-list-modal
        v-if="isShowOperationHistoryModal"
        :reference-id="showingOperationHistoryId"
        reference-type="DeviceLocation"
        v-model:visible="isShowOperationHistoryModal"
      />
    </el-main>
  </div>
</template>

<script>
import { isResOK } from "@/api/response";
import { defineAsyncComponent } from "vue";
import SearchDeviceLocation from "@/view/device_location/Search.vue";
import Pagination from "@/components/Pagination/index.vue";
import {
  delDeviceLocation,
  listDeviceLocation,
  onlineDevice,
  submitDeviceLocation,
} from "@/api/device_location";
import { selectDictValue, selectDictLabel } from "@/utils/ruoyi";
import OperationHistoryListModal from "@/view/operation_history/Modal.vue";
import DictTag from "@/components/DictTag/index.vue";
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";

let baseURL = process.env.VUE_APP_BASE_API;
export default {
  name: "NodeTemplateList",
  dicts: [
    "device_type",
    "device_location_status",
    "device_location_online_status",
    "device_location_installation_location",
    "device_location_installation_method",
  ],
  components: {
    DictTag,
    Pagination,
    SearchDeviceLocation,
    selectDictLabel,
    NewDeviceLocation: defineAsyncComponent(() =>
      import("@/view/device_location/New.vue")
    ),
    EditDeviceLocation: defineAsyncComponent(() =>
      import("@/view/device_location/Edit.vue")
    ),
    DictTagDark: defineAsyncComponent(() =>
      import("@/components/DictTag/DictTagDark.vue")
    ),
    OperationHistoryListModal,
  },

  data() {
    return {
      data: [],
      loading: true,
      total: 0,

      query: {
        pageNum: 1,
        pageSize: 10,
      },

      sort: {
        sort_field: "",
        sort_order: "",
      },

      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowOperationHistoryModal: false, // 是否显示操作历史弹窗
      editingId: 0, // 正在编辑的记录id
      deviceLocationList: [],
      imgUrl: require(`/src/assets/device_location.png`),
      backImgUrl: require(`/src/assets/deivce_location_back.svg`),
      imageUrl: "",
      showImage: false,
      showingOperationHistoryId: 0,
    };
  },

  computed: {},

  created() {
    this.fetchData()
    this.fetchCurrentUser()
  },

  methods: {
    selectDictLabel,
    selectDictValue,
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },

    showNewModal() {
      this.isShowNewModal = true;
    },

    showEditModal(row) {
      this.editingId = row.id;
      this.isShowEditModal = true;
    },

    showOperationHistory(id) {
      this.showingOperationHistoryId = id;
      this.isShowOperationHistoryModal = true;
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({}, { per_page: this.query.per_page }, search);
      this.fetchData();
    },

    handleImage() {
      this.deviceLocationList.forEach((deviceLocation, index) => {
        const installationPositionFile = [];
        const bitmapFile = [];
        const powerLocationFile = [];
        deviceLocation.installationPositionFileList.forEach((file) => {
          installationPositionFile.push(baseURL + file.storageBlob.newFilename);
        });
        deviceLocation.bitmapFileList.forEach((file) => {
          bitmapFile.push(baseURL + file.storageBlob.newFilename);
        });
        deviceLocation.powerLocationFileList.forEach((file) => {
          powerLocationFile.push(baseURL + file.storageBlob.newFilename);
        });
        this.deviceLocationList[index].installationPositionFile = installationPositionFile;
        this.deviceLocationList[index].bitmapFile = bitmapFile;
        this.deviceLocationList[index].powerLocationFile = powerLocationFile;
      });
    },

    fetchData() {
      this.loading = true;
      this.deviceLocationList = [];
      listDeviceLocation(this.query).then((response) => {
          this.deviceLocationList = response.rows;
          this.total = response.total;
          this.handleImage();
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onlineDevice(id, row) {
      this.$messageBox.confirm("确定上线设备吗？", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
        let operatorId = ''
        let operatorName = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo !== undefined){
          operatorId = userInfo.id
          operatorName = userInfo.name
        }
          onlineDevice(id, { operatingMode: 'web', operatorId: operatorId, operatorName: operatorName }).then((res) => {
            if (isResOK(res)) {
              this.$message({
                message: "上线成功",
                type: "success",
              });
            }
            this.fetchData();
          });
        });
    },

    submitDeviceLocation(id) {
      this.$messageBox.confirm("确定进行提交吗？提交后无法修改", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let operatorId = ''
          let operatorName = ''
          const userInfo = JSON.parse(localStorage.getItem('userInfo'));
          if (userInfo !== undefined){
            operatorId = userInfo.id
            operatorName = userInfo.name
          }
          submitDeviceLocation(id, {operatorId: operatorId, operatorName: operatorName}).then((res) => {
            if (isResOK(res)) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
            }
            this.fetchData();
          });
        });
    },

    handleDelete(row) {
      const id = row.id;
      this.$messageBox.confirm("确定删除吗？确定后将同步删除安装计划中此设备以及产生的安装任务", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          delDeviceLocation(id, { operatingMode: 'web' }).then((res) => {
            if (isResOK(res)) {
              this.$message({
                message: "数据删除成功",
                type: "success",
              });
            }
            this.fetchData();
          });
        }).catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
 .title {
   font-size: 17px;
   color: #000000;
   font-weight: bold;
 }
 ::v-deep .el-descriptions__label {
   background-color: #FFFFFF!important;
   width: 106px;
   border: 0px!important;
   vertical-align: top;
 }
 ::v-deep .el-descriptions__content {
   border: 0px!important;
 }
 ::v-deep .el-col-6 {
   background-color: var(--el-fill-color-blank);
 }
</style>
