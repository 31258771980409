<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="85px"
      label-position="left"
    >
      <el-row>
        <el-col :span="6"
          ><el-form-item label="设备编号" prop="deviceNo">
            <el-input
              v-model="queryParams.deviceNo"
              placeholder="请输入设备编号"
              clearable
              @keyup.enter.native="handleQuery"
              style="width: 240px"
            /> </el-form-item
        ></el-col>
        <el-col :span="6"
          ><el-form-item label="设备名称" prop="deviceName">
            <el-input
              v-model="queryParams.deviceName"
              placeholder="请输入设备名称"
              clearable
              @keyup.enter.native="handleQuery"
              style="width: 240px"
            /> </el-form-item
        ></el-col>
        <el-col :span="6">
          <el-form-item label="设备类型" prop="deviceType">
            <el-select
              v-model="queryParams.deviceType"
              placeholder="请选择设备类型"
              style="width: 240px"
            >
              <el-option
                v-for="dict in dict.type.device_type"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              ></el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="6"
          ><el-form-item label="项目名称" prop="projectName">
            <el-input
              v-model="queryParams.projectName"
              placeholder="请输入项目名称"
              clearable
              @keyup.enter.native="handleQuery"
              style="width: 240px"
            /> </el-form-item
        ></el-col>
        <el-col :span="6">
          <el-form-item label="项目类型" prop="projectTypeId">
            <el-select
              v-model="queryParams.projectTypeId"
              placeholder="请选择项目类型"
              @focus="getProductTypeOptions"
              style="width: 240px"
            >
              <el-option
                v-for="item in productTypeOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="6"
          ><el-form-item label="选择状态" prop="status">
            <el-select v-model="queryParams.status" placeholder="请选择状态" style="width: 240px">
              <el-option
                v-for="dict in dict.type.device_location_status"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              ></el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="6"
          ><el-form-item label="选择区域">
            <el-cascader
                :key="cascaderKey"
              v-model="queryParams.areaId"
              placeholder="请选择区域"
              :options="areaOptions"
              :props="{
                value: 'orgId',
                label: 'orgName',
                children: 'subOrgList',
              }"
             style="width: 240px"
            /> </el-form-item
        ></el-col>
        <el-col :span="6"
          ><el-form-item label="上线状态" prop="onlineStatus">
            <el-select
              v-model="queryParams.onlineStatus"
              placeholder="请选择上线状态"
              style="width: 240px"
            >
              <el-option
                v-for="dict in dict.type.device_location_online_status"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              ></el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="handleQuery">搜索</el-button>
            <el-button type="warning" @click="resetQuery">刷新</el-button>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item> </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getProjectTypeOptions } from "@/api/project_type";
import { isResOK, isSysResOK } from "@/api/response";
import { queryOrgRelTree } from "@/api/system";
import { areaOptions } from "@/api/mock";

export default {
  name: "SearchDeviceLocation",
  emits: ["submit"],
  dicts: [
    "device_type",
    "device_location_status",
    "device_location_online_status",
  ],

  data() {
    return {
      queryParams: {
        deviceNo: null,
        deviceName: null,
        deviceType: null,
        projectName: null,
        projectTypeId: null,
        status: null,
        onlineStatus: null,
        areaId: null,
      },
      productTypeOptions: [],
      areaOptions: [],
      cascaderKey: 0,
    }
  },

  created() {
    this.queryOrgRelTree();
  },

  methods: {
    queryOrgRelTree(){
      queryOrgRelTree({
        "orgId": "1681542405985234945",
        "relType": "1"
      }).then(res => {
        if (isSysResOK(res)){
          this.areaOptions.push(res.result.orgRelInfoVo)
        }
      })
    },

    getProductTypeOptions() {
      getProjectTypeOptions().then((res) => {
        if (isResOK(res)) {
          this.productTypeOptions = res.data;
        }
      });
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.$emit("submit", this.queryParams);
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm")
      this.productTypeOptions= []
      this.areaOptions= []
      this.cascaderKey = new Date().getTime()
      this.$emit('submit', this.queryParams)
    },
  },
}
</script>
